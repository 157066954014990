<template>
  <div>
    <v-card flat>
      <v-toolbar flat class="mt-1">
        <v-tooltip bottom right color="primary">
          <template v-slot:activator="{ on }">
            <div v-on="on" class="mr-5 mt-4"><h3>Trial</h3></div>
          </template>
          <span class="white--text"> Has become a Prznce customer </span>
        </v-tooltip>
        <span
          class="ml-n2 mt-4 font-weight-bold"
          style="font-size: 15px; color: #424242"
        >
        {{ creditslist }}
        </span>
        <v-spacer />
        <v-select
          v-model="filtered_ref_id"
          :items="filterItems"
          dense
          style="max-width: 200px"
          class="mt-8 mr-2"
          outlined
          label="Filter By Referral ID"
          item-text="referred_by"
          item-value="referred_by"
        />
        <v-text-field
          v-model="search"
          label="search"
          class="mr-2 mt-8"
          style="max-width: 250px"
          dense
          append-icon="mdi-magnify"
        />
        <v-btn
          @click="exporttoexcel()"
          small
          class="gradient-bg white--text mt-4"
        >
          <v-icon dense class="mr-2">mdi-export</v-icon>Export
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="organisationData"
          :items="organisationItems"
          dense
          class="overflow-y-auto overflow"
          :fixed-header="fixed"
          :height="height"
          :search="search"
          :items-per-page="100"
          :loading="isLoading"
        >
          <template v-slot:[`no-data`]>
            <v-alert class="gradient-bg2 white--text">
              No Organisations are present in this threshold
            </v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
          <template v-slot:[`item.organisation_country`]="{ item }">
            <span
              >{{ item.organisation_country }}<br/>{{
                item.organisation_state == undefined
                  ? "N/A"
                  : item.organisation_state
              }}</span
            >
          </template>

          <template v-slot:[`item.organisation_gst_number`]="{ item }">
            <div
              v-if="
                item.organisation_gst_number == '' ||
                item.organisation_gst_number == null ||
                item.organisation_gst_number == undefined
              "
            >
              <v-icon color="red" small>mdi-alpha-x-circle</v-icon>
            </div>
            <div v-else >
              <v-icon color="green" small>mdi-check-circle</v-icon>
            </div>
          </template>
          <template v-slot:[`item.organisation_created_on`]="{ item }">
            <span>{{ get_date(item.organisation_created_on) }} <br />
              {{
                item.onboarding_type == undefined ? "-" : "("+ item.onboarding_type +")"
              }}
            </span>
          </template>
          <template v-slot:[`item.organisation_name`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <div class="overflow blue--text" v-on="on"   @click="checkItem(item)">
                 <u> {{
                      item.organisation_name.length >= 25
                        ? item.organisation_name.substr(0, 25) + "..."
                        : item.organisation_name
                    }}
                   <!-- {{ item.organisation_name }} -->
                  </u>
                </div>
              </template>
              <span class="white--text">{{ item.organisation_name }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.user_email_id`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <div class="overflow" v-on="on">
                  {{ item.user_email_id }}
                </div>
              </template>
              <span class="white--text">{{ item.user_email_id }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.referred_by`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <span class="overflow" v-on="on">
                  {{
                    item.referred_by != undefined &&
                    item.referred_by != null &&
                    item.referred_by.trim() != ""
                      ? item.referred_by
                      : "-"
                  }}
                </span>
              </template>
              <span class="white--text">{{ item.referred_by }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  color="orange"
                  v-on="on"
                  class="mr-2"
                  @click="opencomments(item)"
                  >mdi-comment-account-outline</v-icon
                >
              </template>
              <span class="white--text">Comments</span>
            </v-tooltip>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  color="red"
                  v-on="on"
                  class="mr-2"
                  @click="opendeactive(item)"
                  >mdi-delete</v-icon
                >
              </template>
              <span class="white--text">Deactivate Customer</span>
            </v-tooltip>
            <!-- <v-btn
              @click="checkItem(item)"
              x-small
              shaped
              class="gradient-bg white--text mr-2"
            >
              view
            </v-btn> -->
            <span
              ><v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click="delete_member_details(item)"
                    :disabled="
                      $store.getters.get_useremail != 'neeraj@mobil80.com'
                    "
                    v-on="on"
                    class="grey"
                    x-small
                    v-show=" $store.getters.get_useremail == 'neeraj@mobil80.com'"
                  >
                  
                    <v-icon class="black--text" small>mdi-incognito</v-icon>
                  </v-btn>
                </template>
                <span class="white--text">Impersonate ?</span>
              </v-tooltip></span
            >
          </template>
          <template v-slot:[`item.last_swiped_on`]="{ item }">
            <span v-if="item.last_swiped_on == undefined" class="ml-5">-</span>
            <span v-else
              >{{ get_date(item.last_swiped_on) }}
              <br />
              {{ get_time(item.last_swiped_on) }}</span
            >
          </template>
          <!-- <template
            v-for="(h, i) in organisationData"
            v-slot:[`header.${h.value}`]="{ header }"
          >
            <div :key="i">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ header.text }}</span>
                </template>
                <span
                  class="white--text"
                  v-if="header.tooltip_text != undefined"
                  >{{ header.tooltip_text }}</span
                >
              </v-tooltip>
            </div>
          </template> -->
          <template v-slot:[`item.organisation_total_credits`]="{ item }">
            <span class="mr-5">{{ item.organisation_total_credits }}</span>
          </template>
          <template v-slot:[`item.totalconsumed`]="{ item }">
            <span>{{ item.total_consumed_credits }}</span>
          </template>
          <template v-slot:[`item.organisation_total_locations`]="{ item }">
            <span
              class="mr-4"
              v-text="
                item.organisation_total_locations < 10
                  ? '0' + item.organisation_total_locations
                  : item.organisation_total_locations
              "
            ></span>
            <span
              class="mr-4"
              v-text="
                item.organisation_total_admin < 10
                  ? '0' + item.organisation_total_admin
                  : item.organisation_total_admin
              "
            ></span>
            <span
              v-text="
                item.organisation_total_users < 10
                  ? '0' + item.organisation_total_users
                  : item.organisation_total_users
              "
            ></span>
          </template>
          <!-- <template v-slot:[`item.referred_by`]="{ item }">
            {{item}}
          </template> -->
        </v-data-table>
      </v-card-text>
    </v-card>

    <Overlay :overlay="overlay" />
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <ViewOrganisationDialog
      :from_change_ref="from_change_ref"
      :viewOrgDialog="viewOrgDialog"
      :OrgObject="OrgObject"
      :AllOrgObject="sorted_organisationItems"
      :fromOrg="'CUSTOMER'"
      @close_view_org_dialog="viewOrgDialog = false"
      @get_refreshed_orgs="refresh_org_open_again"
      @get_refreshed_orgs2="refresh_org_open_again2"
    />
    <DeleteDialog
      :confDeleteDialog="confDeleteDialog"
      @close_conf_delete_dialog="confDeleteDialog = false"
      :deleteText="deleteText"
      :deleteIcon="deleteIcon"
      :deleteColor="deleteColor"
      @success_conf_delete="deleteSuccessAction"
      :deleteLoading="deleteLoading"
    />
    <OrgDeactive
      :Orgdeletion="Orgdeletion"
      :getObject="getObject"
      v-on:errorMsg="error_info"
      v-on:successMsg="success_info"
      @clicked="Orgdeletion = false"
    />
    <OrgComments
      :commentsOrgDailog="commentsOrgDailog"
      :getObject="getObject"
      v-on:errorMsg="error_info"
      v-on:successMsg="success_info"
      @clicked="commentsOrgDailog = false"
    />
  </div>
</template>

<script>
import OrgDeactive from "../../Dialogs/Organisations/OrgDeactive.vue";
import Overlay from "@/components/Overlay.vue";
import SnackBar from "@/components/SnackBar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { ListAllOrganisationByStatus } from "@/graphql/queries.js";
import ViewOrganisationDialog from "@/components/Dialogs/Organisations/ViewOrganisationDialog.vue";
import { parseTime } from "@/utils";
import DeleteDialog from "@/components/Dialogs/ConfirmationDialogs/DeleteDialog.vue";
import { CreateInpersinationUser } from "@/graphql/mutations.js";
import { CountOfOrganisation } from "@/graphql/queries.js";
import OrgComments from "@/components/Dialogs/Organisations/OrgComments.vue";
export default {
  components: {
    Overlay,
    SnackBar,
    OrgComments,
    ViewOrganisationDialog,
    DeleteDialog,
    OrgDeactive
  },
  data: () => ({
    from_change_ref: false,
    filtered_ref_id: "ALL",
    deleteLoading: false,
    confDeleteDialog: false,
    deleteColor: "",
    deleteIcon: "",
    deleteText: "",
    Orgdeletion: false,
    getObject: {},
    viewOrgDialog: false,
    OrgObject: {},
    organisationData: [
      {
        text: "Organization Name",
        tooltip_text: "Organization Name",
        value: "organisation_name",
        sortable: false,
      },
      {
        text: "DCC",
        value: "daily_consumed_credits",
        tooltip_text: "Consumed",
        sortable: true,
      },
      {
        text: "Country /State",
        value: "organisation_country",
        tooltip_text: "Consumed",
        sortable: true,
      },
      {
        text: "GST",
        value: "organisation_gst_number",
        tooltip_text: "Consumed",
        sortable: true,
      },
      {
        text: "Last Swiped",
        value: "last_swiped_on",
        tooltip_text: "Last Swiped",
        sortable: true,
      },
      // {
      //   text: "E-Mail",
      //   tooltip_text: "E-Mail",
      //   value: "user_email_id",
      //   sortable: false,
      // },
      // {
      //   text: "Referred By",
      //   tooltip_text: "Referred By",
      //   value: "referred_by",
      //   sortable: false,
      // },
      {
        text: "Registered On",
        tooltip_text: "Registered On",
        value: "organisation_created_on",
        sortable: false,
      },
      {
        text: "Available",
        tooltip_text: "Total Credits",
        value: "organisation_total_credits",
        sortable: false,
      },
      {
        text: "Consumed",
        tooltip_text: "Consumed Credits",
        value: "totalconsumed",
        sortable: false,
      },
      // {
      //   text: "#L  / #A  / #M",
      //   value: "organisation_total_locations",
      //   tooltip_text: "#Locations / #Admins / #Members",
      //   sortable: false,
      // },
      {
        text: "Actions",
        tooltip_text: "Actions",
        value: "Actions",
        sortable: false,
      },
      // { text: "Impersonate ", value: "imp", sortable: false },
    ],
    organisationItems: [],
    sorted_organisationItems: [],
    commentsOrgDailog: false,
    filterItems: [],
    organisationNewItems: [],
    search: "",
    fixed: true,
    height: 0,
    overlay: false,
    SnackBarComponent: {},
    isLoading: false,
    organisationCount:{},
    creditslist:"",
  }),
  mounted() {
    this.height = window.innerHeight - 210;
    this.isLoading = true;
    this.overlay = true;
    this.get_organisation_list();
    this.get_organisation_count();
  },
  watch: {
    filtered_ref_id(val) {
      if (val == "ALL") {
        this.organisationItems = this.organisationNewItems;
      } else if (val == "NONE") {
        this.organisationItems = this.organisationNewItems.filter(
          (item) => item.referred_by == undefined
        );
      } else {
        this.organisationItems = this.organisationNewItems.filter(
          (item) => item.referred_by == val
        );
      }
    },
  },
  methods: {
    get_time(timestamp) {
      let time = new Date(timestamp * 1000).toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return time.toUpperCase();
    },
    opencomments(item) {
      this.getObject = item;

      this.commentsOrgDailog = true;
    },
    async get_organisation_count() {
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(CountOfOrganisation, {
            input: {},
          })
        );
        // console.log(result);
        this.organisationCount = JSON.parse(result.data.CountOfOrganisation);
        this.creditslist= this.organisationCount.Trial_map
        // console.log("organisationCount", this.organisationCount);
        this.$forceUpdate();
        this.overlay = false;
      } catch (err) {
        console.log("error", err);
        this.overlay = false;
      }
    },
    get_date2(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    opendeactive(item) {
      this.getObject = item;
      this.Orgdeletion = true;
    },
    error_info(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };
    },
    success_info(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };
      this.Orgdeletion = false;
      this.commentsOrgDailog = false;
      this.get_organisation_list();
    },
    delete_member_details(item) {
      this.deleteUserItem = item;
      this.confDeleteDialog = true;
      this.deleteText = `This action is available for 1 hour for the Organisation- ${item.organisation_name}. Click on OK to continue.`;
      this.deleteIcon = "mdi-incognito";
      this.deleteColor = "grey darken-4";
    },
    deleteSuccessAction() {
      this.deleteLoading = true;
      this.deactivate_reactivate_members(this.deleteUserItem);
    },
    async deactivate_reactivate_members(item) {
      this.deleteLoading = true;
      try {
        await API.graphql(
          graphqlOperation(CreateInpersinationUser, {
            input: {
              organisation_id: item.organisation_id,
              user_email_id: this.$store.getters.get_useremail,
              user_name: this.$store.getters.get_useremail.split("@")[0],
            },
          })
        ).then((res) => {
          this.$store.commit("snackbar/showMessage", {
            content: JSON.parse(res.data.CreateInpersinationUser).Message,
            color: "green",
          });
          this.get_organisation_list();
          this.deleteLoading = false;
          this.confDeleteDialog = false;
        });
      } catch (err) {
        this.$store.commit("snackbar/showMessage", {
          content: err.errors[0].message,
          color: "red",
        });
        this.deleteLoading = false;
      }
    },
    exporttoexcel() {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "Organization Name",
          "E Mail",
          "Referred By",
          "Registered On",
          "Address",
          "Phone No",
          "#Locations",
          "#Admins",
          "#Members",
        ];
        const filterVal = [
          "organisation_name",
          "user_email_id",
          "referred_by",
          "organisation_created_ons",
          "organisation_address",
          "user_full_contact_number",
          "organisation_total_locations",
          "organisation_total_admin",
          "organisation_total_users",
        ];
        const list = this.organisationItems;
        var self = this;
        list.forEach(function (findx) {
          findx.organisation_created_ons = self.get_date(
            findx.organisation_created_on
          );
        });
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: `Organization List ${this.get_date_export()}`,
        });
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j === "timestamp") {
            return parseTime(v[j]);
          } else {
            return v[j];
          }
        })
      );
    },
    async refresh_org_open_again() {
      this.viewOrgDialog = false;
      this.OrgObject = this.$store.getters.get_latest_org;
      await this.get_organisation_list().then(() => {
        let new_item = this.organisationItems.filter(
          (val) => val.organisation_id == this.OrgObject.organisation_id
        );
        this.checkItem(new_item[0]);
      });
    },
    checkItem(item) {
      this.OrgObject = item;
      this.from_change_ref = false;
      this.viewOrgDialog = true;
      this.$store.commit("set_latest_org", this.OrgObject);
    },

    async refresh_org_open_again2() {
      this.viewOrgDialog = false;
      this.OrgObject = this.$store.getters.get_latest_org;
      await this.get_organisation_list().then(() => {
        let new_item = this.organisationItems.filter(
          (val) => val.organisation_id == this.OrgObject.organisation_id
        );
        this.checkItem2(new_item[0]);
      });
    },
    checkItem2(item) {
      this.OrgObject = item;
      this.from_change_ref = true;
      this.viewOrgDialog = true;
      this.$store.commit("set_latest_org", this.OrgObject);
    },

    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return a.split(",")[0].toUpperCase();
    },
    get_date_export() {
      let a = new Date().toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return a.split(",")[0].toUpperCase();
    },
    async get_organisation_list() {
      this.isLoading = true;
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(ListAllOrganisationByStatus, {
            input: {
              organisation_status: "ACTIVE",
              organisation_type: "TRIAL",
            },
          })
        );
        this.organisationNewItems = JSON.parse(
          result.data.ListAllOrganisationByStatus
        );
        this.organisationItems = JSON.parse(
          result.data.ListAllOrganisationByStatus
        );
        this.filterItems = this.organisationNewItems.filter(
          (val) => val.referred_by
        );
        this.filterItems.unshift("NONE");
        this.filterItems.unshift("ALL");
        this.sorted_organisationItems = JSON.parse(
          result.data.ListAllOrganisationByStatus
        ).sort((a, b) => (a.organisation_name > b.organisation_name ? 1 : -1));
        this.isLoading = false;
        this.overlay = false;
      } catch (err) {
        console.log("error", err);
        this.isLoading = false;
        this.overlay = false;
      }
    },
  },
};
</script>

<style></style>
