var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{staticClass:"mt-5",attrs:{"dense":"","elevation":"0"}},[_c('div',{staticClass:"mr-6"},[_c('h3',[_vm._v("Recent")])]),_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"15px","color":"#424242"}},[_vm._v(" "+_vm._s(_vm.creditslist)+" ")]),_c('v-spacer'),_c('v-text-field',{staticClass:"ml-2 mt-8",staticStyle:{"max-width":"250px"},attrs:{"label":"search","dense":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{staticClass:"overflow-y-auto overflow",attrs:{"headers":_vm.recentheaders,"items":_vm.recentorgitems,"dense":"","fixed-header":_vm.fixed,"height":_vm.height,"search":_vm.search,"loading":_vm.isLoading,"items-per-page":100},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text"},[_vm._v(" No Recent Organisations ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text",attrs:{"value":true}},[_vm._v(" No records found! ")])]},proxy:true},{key:"item.organisation_country",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.organisation_country)+"/"+_vm._s(item.organisation_state == undefined ? "N/A" : item.organisation_state))])]}},{key:"item.organisation_gst_number",fn:function(ref){
var item = ref.item;
return [(
              item.organisation_gst_number == '' ||
              item.organisation_gst_number == null ||
              item.organisation_gst_number == undefined
            )?_c('div',[_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-alpha-x-circle")])],1):_c('div',[_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("mdi-check-circle")])],1)]}},{key:"item.organisation_created_on",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.get_date(item.organisation_created_on))+" "),_c('br'),_vm._v(" "+_vm._s(item.onboarding_type == undefined ? "-" : "("+ item.onboarding_type +")"))])]}},{key:"item.organisation_name",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('u',[_c('div',_vm._g({staticClass:"overflow blue--text",on:{"click":function($event){return _vm.checkItem(item)}}},on),[_vm._v(" "+_vm._s(item.organisation_name)+" ")])])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.organisation_name))])])]}},{key:"item.last_swiped_on",fn:function(ref){
            var item = ref.item;
return [(item.last_swiped_on == undefined)?_c('span',{staticClass:"ml-5"},[_vm._v("-")]):_c('span',{domProps:{"textContent":_vm._s(_vm.get_date2(item.last_swiped_on))}})]}}],null,true)})],1)],1),_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),_c('ViewOrganisationDialog',{attrs:{"from_change_ref":_vm.from_change_ref,"viewOrgDialog":_vm.viewOrgDialog,"OrgObject":_vm.OrgObject,"AllOrgObject":_vm.sorted_organisationItems},on:{"close_view_org_dialog":function($event){_vm.viewOrgDialog = false},"get_refreshed_orgs":_vm.refresh_org_open_again,"get_refreshed_orgs2":_vm.refresh_org_open_again2}}),_c('Overlay',{attrs:{"overlay":_vm.overlay}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }