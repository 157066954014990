<template>
  <div>
    <v-card flat>
      <v-toolbar dense elevation="0" class="mt-5">
        <div class="mr-10"><h3>Deactivated</h3></div>
        <v-spacer />
        <v-text-field
          v-model="search"
          label="search"
          class="ml-2 mt-8"
          style="max-width: 250px"
          dense
          append-icon="mdi-magnify"
        />
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="deheaders"
          :items="deactiveitems"
          dense
          class="overflow-y-auto overflow"
          :fixed-header="fixed"
          :height="height"
          :search="search"
          :loading="isLoading"
          :items-per-page="100"
        >
          <template v-slot:[`item.action_taken_on`]="{ item }">
            <span v-if="item.action_taken_on == undefined">-</span>

            <span v-else v-text="get_date(item.action_taken_on)"></span>
          </template>
          <template v-slot:[`item.action_taken_by`]="{ item }">
            <span v-if="item.action_taken_by == undefined">-</span>
            <span v-else>{{ item.action_taken_by }}</span>
          </template>
          <template v-slot:[`no-data`]>
            <v-alert class="gradient-bg2 white--text">
              No Deactivated Organisations
            </v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  color="red"
                  v-on="on"
                  class="mr-2"
                  @click="opendeactive(item)"
                  >mdi-account-reactivate</v-icon
                >
              </template>
              <span class="white--text">Reactivate Customer</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <Overlay :overlay="overlay" />
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <OrgReactive
      :reorgactive="reorgactive"
      :getObject="getObject"
      v-on:errorMsg="error_info"
      v-on:successMsg="success_info"
      @clicked="reorgactive = false"
    />
  </div>
</template>

<script>
import Overlay from "@/components/Overlay.vue";
import SnackBar from "@/components/SnackBar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { ListAllOrganisationByStatus } from "@/graphql/queries.js";
import OrgReactive from "../../Dialogs/Organisations/OrgReactive.vue";
export default {
  components: {
    OrgReactive,
    SnackBar,
    Overlay,
  },
  data() {
    return {
      deheaders: [
        { text: "Organization Name ", value: "organisation_name" },
        { text: "E-Mail", value: "user_email_id" },

        { text: "Deactivated By", value: "action_taken_by" },
        { text: "Deactivated On", value: "action_taken_on" },
        { text: "Action", value: "actions", sortable: false },
      ],
      deactiveitems: [],
      SnackBarComponent: {},
      height: 0,
      fixed: true,
      getObject: {},
      overlay: false,
      search: "",
      reorgactive: false,
      isLoading: false,
    };
  },
  created() {
    this.height = window.innerHeight - 210;
    this.get_organisation_list();
  },
  methods: {
    get_date(date) {
      // console.log(date, "data");
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      // console.log(a);
      return a.toUpperCase();
    },
    success_info(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };
      this.reorgactive = false;
      this.get_organisation_list();
    },
    error_info(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };
    },
    opendeactive(item) {
      this.getObject = item;
      this.reorgactive = true;
    },
    async get_organisation_list() {
      this.isLoading = true;
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(ListAllOrganisationByStatus, {
            input: {
              organisation_status: "ACTIVE",
              organisation_type: "DEACTIVATED",
            },
          })
        );

        this.isLoading = false;
        this.overlay = false;
        this.deactiveitems = JSON.parse(
          result.data.ListAllOrganisationByStatus
        );
        // console.log(result.data.ListAllOrganisationByStatus);
        // this.organisationNewItems = JSON.parse(
        //   result.data.ListAllOrganisationByStatus
        // );
        // this.organisationItems = JSON.parse(
        //   result.data.ListAllOrganisationByStatus
        // );

        this.isLoading = false;
        this.overlay = false;
      } catch (err) {
        this.isLoading = false;
        this.overlay = false;
        console.log("error", err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
      }
    },
  },
};
</script>

<style>
</style>