var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{staticClass:"mt-5",attrs:{"dense":"","elevation":"0"}},[_c('div',{staticClass:"mr-10"},[_c('h3',[_vm._v("Deactivated")])]),_c('v-spacer'),_c('v-text-field',{staticClass:"ml-2 mt-8",staticStyle:{"max-width":"250px"},attrs:{"label":"search","dense":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{staticClass:"overflow-y-auto overflow",attrs:{"headers":_vm.deheaders,"items":_vm.deactiveitems,"dense":"","fixed-header":_vm.fixed,"height":_vm.height,"search":_vm.search,"loading":_vm.isLoading,"items-per-page":100},scopedSlots:_vm._u([{key:"item.action_taken_on",fn:function(ref){
var item = ref.item;
return [(item.action_taken_on == undefined)?_c('span',[_vm._v("-")]):_c('span',{domProps:{"textContent":_vm._s(_vm.get_date(item.action_taken_on))}})]}},{key:"item.action_taken_by",fn:function(ref){
var item = ref.item;
return [(item.action_taken_by == undefined)?_c('span',[_vm._v("-")]):_c('span',[_vm._v(_vm._s(item.action_taken_by))])]}},{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text"},[_vm._v(" No Deactivated Organisations ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text",attrs:{"value":true}},[_vm._v(" No records found! ")])]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.opendeactive(item)}}},on),[_vm._v("mdi-account-reactivate")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Reactivate Customer")])])]}}],null,true)})],1)],1),_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),_c('OrgReactive',{attrs:{"reorgactive":_vm.reorgactive,"getObject":_vm.getObject},on:{"errorMsg":_vm.error_info,"successMsg":_vm.success_info,"clicked":function($event){_vm.reorgactive = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }