<template>
  <div>
    <v-card flat>
      <v-toolbar dense elevation="0" class="mt-5">
        <div class="mr-6"><h3>Recent</h3></div>
        <span class="font-weight-bold" style="font-size: 15px; color: #424242">
          {{ creditslist }}
        </span>
        <v-spacer />
        <v-text-field
          v-model="search"
          label="search"
          class="ml-2 mt-8"
          style="max-width: 250px"
          dense
          append-icon="mdi-magnify"
        />
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="recentheaders"
          :items="recentorgitems"
          dense
          class="overflow-y-auto overflow"
          :fixed-header="fixed"
          :height="height"
          :search="search"
          :loading="isLoading"
          :items-per-page="100"
        >
          <template v-slot:[`no-data`]>
            <v-alert class="gradient-bg2 white--text">
              No Recent Organisations
            </v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
          <template v-slot:[`item.organisation_country`]="{ item }">
            <span
              >{{ item.organisation_country }}/{{
                item.organisation_state == undefined
                  ? "N/A"
                  : item.organisation_state
              }}</span
            >
          </template>

          <template v-slot:[`item.organisation_gst_number`]="{ item }">
            <div
              v-if="
                item.organisation_gst_number == '' ||
                item.organisation_gst_number == null ||
                item.organisation_gst_number == undefined
              "
            >
              <v-icon color="red" small>mdi-alpha-x-circle</v-icon>
            </div>
            <div v-else >
              <v-icon color="green" small>mdi-check-circle</v-icon>
            </div>
          </template>
          <template v-slot:[`item.organisation_created_on`]="{ item }">
            <span>{{ get_date(item.organisation_created_on) }}
              <br />
              {{
                item.onboarding_type == undefined ? "-" : "("+ item.onboarding_type +")"
              }}</span>
          </template>
          <template v-slot:[`item.organisation_name`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <u>
                  <div
                    class="overflow blue--text"
                    v-on="on"
                    @click="checkItem(item)"
                  >
                    {{ item.organisation_name }}
                  </div></u
                >
              </template>
              <span class="white--text">{{ item.organisation_name }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.last_swiped_on`]="{ item }">
            <span v-if="item.last_swiped_on == undefined" class="ml-5">-</span>
            <span v-else v-text="get_date2(item.last_swiped_on)"></span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <ViewOrganisationDialog
      :from_change_ref="from_change_ref"
      :viewOrgDialog="viewOrgDialog"
      :OrgObject="OrgObject"
      :AllOrgObject="sorted_organisationItems"
      @close_view_org_dialog="viewOrgDialog = false"
      @get_refreshed_orgs="refresh_org_open_again"
      @get_refreshed_orgs2="refresh_org_open_again2"
    />
    <Overlay :overlay="overlay" />
  </div>
</template>

<script>
import Overlay from "@/components/Overlay.vue";
import ViewOrganisationDialog from "@/components/Dialogs/Organisations/ViewOrganisationDialog.vue";
import SnackBar from "@/components/SnackBar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { ListAllOrganisationByStatus } from "@/graphql/queries.js";
import { CountOfOrganisation } from "@/graphql/queries.js";
export default {
  components: {
    Overlay,
    SnackBar,
    ViewOrganisationDialog,
  },
  data() {
    return {
      recentheaders: [
        { text: "Organization Name ", value: "organisation_name" },
        {
          text: "DCC",
          value: "daily_consumed_credits",
          tooltip_text: "Consumed",
          sortable: true,
        },
        {
        text: "Country /State",
        value: "organisation_country",
        tooltip_text: "Consumed",
        sortable: true,
      },
      {
        text: "GST",
        value: "organisation_gst_number",
        tooltip_text: "Consumed",
        sortable: true,
      },
        {
          text: "Last Swiped On",
          value: "last_swiped_on",
          tooltip_text: "Last Swiped",
          sortable: true,
        },
        // { text: "E-Mail", value: "user_email_id", sortable: false },
        {
          text: "Registered On",
          value: "organisation_created_on",

          sortable: false,
        },
        {
          text: "Available",

          value: "organisation_total_credits",
          sortable: false,
        },
        {
          text: "Consumed",

          value: "total_consumed_credits",
          sortable: false,
        },
        // { text: "Action", value: "actions", sortable: false },
      ],
      recentorgitems: [],
      overlay: false,
      SnackBarComponent: {},
      OrgObject: {},
      height: 0,
      fixed: true,
      search: "",
      isLoading: false,
      viewOrgDialog: false,
      sorted_organisationItems: [],
      from_change_ref: false,
      organisationCount: {},
      creditslist: "",
    };
  },
  created() {
    this.height = window.innerHeight - 210;
    this.get_organisation_list();
    this.get_organisation_count();
  },
  methods: {
    async get_organisation_count() {
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(CountOfOrganisation, {
            input: {},
          })
        );
        // console.log(result);
        this.organisationCount = JSON.parse(result.data.CountOfOrganisation);
        this.creditslist = this.organisationCount.Recent_map;
        // console.log("organisationCount", this.organisationCount);
        this.$forceUpdate();
        this.overlay = false;
      } catch (err) {
        console.log("error", err);
        this.overlay = false;
      }
    },
    get_date2(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    checkItem(item) {
      this.OrgObject = item;
      this.from_change_ref = false;
      this.viewOrgDialog = true;
      this.$store.commit("set_latest_org", this.OrgObject);
    },
    async refresh_org_open_again() {
      this.viewOrgDialog = false;
      this.OrgObject = this.$store.getters.get_latest_org;
      await this.get_organisation_list().then(() => {
        let new_item = this.organisationItems.filter(
          (val) => val.organisation_id == this.OrgObject.organisation_id
        );
        this.checkItem(new_item[0]);
      });
    },
    async refresh_org_open_again2() {
      this.viewOrgDialog = false;
      this.OrgObject = this.$store.getters.get_latest_org;
      await this.get_organisation_list().then(() => {
        let new_item = this.organisationItems.filter(
          (val) => val.organisation_id == this.OrgObject.organisation_id
        );
        this.checkItem2(new_item[0]);
      });
    },
    checkItem2(item) {
      this.OrgObject = item;
      this.from_change_ref = true;
      this.viewOrgDialog = true;
      this.$store.commit("set_latest_org", this.OrgObject);
    },
    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return a.split(",")[0].toUpperCase();
    },
    async get_organisation_list() {
      this.isLoading = true;
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(ListAllOrganisationByStatus, {
            input: {
              organisation_status: "ACTIVE",
              organisation_type: "RECENTONBOARD",
            },
          })
        );

        this.isLoading = false;
        this.overlay = false;
        this.recentorgitems = JSON.parse(
          result.data.ListAllOrganisationByStatus
        );
        // console.log(this.recentorgitems);
        // console.log(result.data.ListAllOrganisationByStatus);
        this.organisationNewItems = JSON.parse(
          result.data.ListAllOrganisationByStatus
        );
        this.organisationItems = JSON.parse(
          result.data.ListAllOrganisationByStatus
        );
        this.sorted_organisationItems = JSON.parse(
          result.data.ListAllOrganisationByStatus
        ).sort((a, b) => (a.organisation_name > b.organisation_name ? 1 : -1));

        this.isLoading = false;
        this.overlay = false;
      } catch (err) {
        this.isLoading = false;
        this.overlay = false;
        console.log("error", err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
      }
    },
  },
};
</script>

<style>
</style>